<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}

.intro{font-size: 16px; color:#890720; padding:10px}
.row{flex:0 0 auto; margin:20px; display:flex; align-items: flex-end; font-size:15px; color:#666; }
.row .check{width:140px;}
.row .line{display: flex; align-items: flex-end;}
.row .el-input{height:19px; line-height: 19px;}
.row .el-input__inner{border-radius: 0; border-top:0; border-left:0; border-right:0; border-bottom:solid 1px #409EFF; height:19px; line-height: 19px; padding:0 0 3px 0; margin:0; text-align: center; font-size:16px; color:#327bc7; font-weight: bold;}
.houseedit .el-row:nth-of-type(n+2){margin-top: 20px;}
.houseedit .no-margin{margin-top: 5px !important;}
.houseedit .intro{font-size: 13px; color: #444; padding: 0}
</style>
<template>
    <div class="component">
        <!-- 修改框 -->
        <el-dialog width="400px" custom-class="houseedit" title="编辑名称" v-model="show_edit" :close-on-click-modal="false">
            <el-row class="label">
                <el-col :span="4">名称</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.name" placeholder="请输入内容"></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="4">数值</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.value" placeholder="请输入内容"></el-input>
                </el-col>
            </el-row>
            <el-row class="no-margin">
                <el-col :span="4"></el-col>
                <el-col :span="18"><span class="intro">数值指引用表达式{name}、{value}所使用的值</span></el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 修改框结束 -->

        <div class="title-panel">
            <el-row>
                <el-col :span="12">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">房屋配置</div><div class="descript">管理当前小区的住宅、商业、车位配置，生成级联选单供选择使用。</div></div>
                </el-col>
                <el-col :span="12" class="btn-col">
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_root">刷新数据</el-button>
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加节点</el-button>
                    
                </el-col>
            </el-row>
        </div>
        <HouseAdd ref="houseadd" @house_update="house_update"></HouseAdd>
        <div class="table-box" id="table">
            <el-table ref="table" :data="list" lazy :load="lazy_load_list" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" border :max-height="table_height">
                <el-table-column prop="name" label="名称" width="280"></el-table-column>
                <el-table-column prop="level" label="层级" width="120" :formatter="(row,col,val)=>{return '第 '+val+' 层级'}"></el-table-column>
                <el-table-column prop="value" label="数值" width="100" align="center"></el-table-column>
                <el-table-column prop="name_path" label="路径" width="380" :formatter="(row,col,val)=>{ return val.replace(/,/g,' / '); }"></el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_del(scope)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import {inject} from 'vue'
import HouseAdd from '../components/HouseAdd.vue'
export default {
    components:{HouseAdd},
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            list:[],
            show_edit:false,
            data_for_edit:{},
            table_height:800,
        }
    },//data end

    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table').offsetHeight -12; });
        this.init();
    },//mounted end
    watch:{
        'gd.me.eid':function(){
            this.load_root();
        }
    },
    methods:{
        init: function(){
            this.load_root();
        },//init
        load_root:function(){
            this.axios.post("/api/house/list").then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                this.list = ret.data;
            });
        },//load_root
        lazy_load_list:function(tree, treeNode, resolve){
            //console.log(tree, treeNode);
            if(tree.id && !isNaN(tree.id)){
                this.axios.post("/api/house/list",{id:tree.id}).then(ret=>{
                    if(!ret.ok){ this.$message.error(ret.msg); return;}
                    ret.data.map(item=>{
                        item.pid = tree.id;
                        item.presolve = resolve;
                    });
                    resolve(ret.data);
                });
            }   
        },//lazy_load_list
        house_update:function(){
            this.load_root();
            this.$refs.table.doLayout();
        },//house_update
        click_add:function(){
            this.$refs.houseadd.show();
        },//click_add
        click_edit:function(row){
            this.data_for_edit = row;
            this.show_edit = true;
        },//click_edit
        click_del:function(scope){
            this.axios.post("/api/house/del",{id:scope.row.id}).then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                this.$message.success("操作成功");
                this.show_edit = false;
                //重新读取父节点
                this.axios.post("/api/house/list",{id:scope.row.pid}).then(ret=>{
                    if(!ret.ok){ this.$message.error(ret.msg); return;}
                    ret.data.map(item=>{
                        item.pid = scope.row.pid;
                        item.presolve = scope.row.presolve;
                    });
                    scope.row.presolve(ret.data);
                });

            });
        },//click_del
        click_edit_done:function(){
            if(this.data_for_edit.name == ''){ this.$message.error("请输入名称"); return;}
            if(this.data_for_edit.value == ''){ this.$message.error("请输入数值"); return;}
            this.axios.post("/api/house/edit",{id:this.data_for_edit.id, name:this.data_for_edit.name, value:this.data_for_edit.value}).then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return;}
                this.$message.success("操作成功");
                this.show_edit = false;
            });
        },//click_edit_done
        // number_to_zh:function(num){
        //     let zh_template = ['','一','二','三','四','五','六','七','八','九','十'];
        //     if(num<11){ return zh_template[num];  }
        //     else if(num<20){ return '十'+zh_template[num-10];  }
        //     else if(num<100){ return zh_template[Math.floor(num /10)]+'十'+zh_template[num %10];  }
        //     else{ return '一百';  }
        // },//number_to_zh
        // number_to_letter:function(num){
        //     if(num < 1 || num > 26){ this.$message.error("英文字母最大支持26。"); return;}
        //     return String.fromCharCode(64+num);
        // },//number_to_zh
        
        // click_done:function(){
        //     this.axios.post("/api/estate/bufh_set",{build:this.build, unit:this.unit, floor:this.floor, house:this.house}).then(ret=>{
        //         if(!ret.ok){ this.$message.error(ret.msg); return;}
        //         this.$message.success(ret.msg);
        //     });
        // },//click_done
    },//methods end
};
</script>