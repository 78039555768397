<style type="text/css">
.houseadd{max-height:80vh; margin-top:15vh !important;}
.houseadd .el-row:nth-of-type(n+2){margin-top:20px;}
.houseadd .no-margin{margin-top: 5px !important;}
.houseadd .intro{font-size: 13px; color: #444; padding: 0}
.houseadd .el-row .label{color:#909399; font-size:15px; text-align: right}
.houseadd .el-dropdown-link{font-size: 15px;}
.houseadd .top{display: flex; align-items: flex-start;}
.houseadd .el-space__item{cursor:pointer;}
.houseadd .file-pad{display: flex; flex-direction: column; align-items: stretch;}
.houseadd .file-pad .file{flex: 1 1 auto; padding:5px; display: flex; align-items: center; border:solid 1px #fff;}
.houseadd .file-pad .file:hover{ border-radius:4px; border-color:#b3d8ff; background:#ecf5ff;}
.houseadd .file-pad .type{flex:0 0 auto; margin-right:5px;}
.houseadd .file-pad .name{flex:1 1 auto; overflow: hidden;}
.houseadd .file-pad .file-del{flex:0 0 auto; margin-left:10px; cursor:pointer; color:#f00}
.houseadd .file-pad .szie{flex:0 0 auto; width:100px;}
</style>
<template>
    <div>
        <el-dialog width="600px" custom-class="houseadd" title="添加节点" v-model="display" :close-on-click-modal="false">
            <el-row :gutter="10">
                <el-col :span="4" class="label">房屋类型</el-col>
                <el-col :span="18">
                    <el-radio-group v-model="data_for_edit.type">
                        <el-radio-button label="1">住宅</el-radio-button>
                        <el-radio-button label="2">商业</el-radio-button>
                        <el-radio-button label="3">车位</el-radio-button>
                    </el-radio-group>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="4" class="label">添加方式</el-col>
                <el-col :span="18">
                    <el-radio-group v-model="data_for_edit.pid_type">
                        <el-radio-button label="1">为单个节点添加子节点</el-radio-button>
                        <el-radio-button label="2">为多个节点添加子节点</el-radio-button>
                    </el-radio-group>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="no-margin">
                <el-col :span="4"></el-col>
                <el-col :span="18"><span class="intro" v-html="pid_type_intro"></span></el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="4" class="label">选择父节点</el-col>
                <el-col :span="18">
                    <el-cascader show-all-levels v-model="data_for_edit.pid" :props="props" style="width:100%" ref="pnode" @change="$refs.pnode.togglePopperVisible()" :key="cascader_key">
                    </el-cascader>
                </el-col>
            </el-row>
            <el-row :gutter="10" v-if="data_for_edit.pid_type == '2'">
                <el-col :span="4" class="label">选择层级</el-col>
                <el-col :span="18">
                    <el-select v-model="data_for_edit.level" placeholder="第N级节点" :key="cascader_key">
                        <el-option v-for="(item,idx) in level" :key="idx" :label="'第 '+item.level+' 层级'" :value="item.level"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="4" class="label">名称模板</el-col>
                <el-col :span="14">
                    <el-input v-model="data_for_edit.tpl" placeholder="输入生成名称的模板，" clearable style="width:100%" />
                </el-col>
                <el-col :span="4">
                    <el-button type="success" plain @click="click_test">预览</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="10" class="no-margin">
                <el-col :span="1"></el-col>
                <el-col :span="23"><span class="intro" style="line-height: 180%; font-family: 'arial';">
                    模板由文字和表达式组成，表达式由指令:值 构成，用花括符“{}”包裹，经过计算替换为文本。<br>
                    <b>范围表达式</b>：{range:x,y|pad:z} range是范围指令，x、y是两个数字，效果：生成从x到y的连续数字。|pad:z是扩展指令，表示生成的数字长度不足z位时左补0到z位。<br>
                    <b>引用表达式</b>：{name} 引用父节点名称； {value}引用父节点数值。 value可追加扩展指令，实现前补0。例如{value|pad:2}。name 和 value 不能同时使用。<br>
                    例如：{range:1,15}栋、{name:1}{range:1,2}单元、{range:1,26}楼、{value:3}{range:1,4|pad:2}号房</span>
                </el-col>
            </el-row>

            <el-row :gutter="10" class="top">
                <el-col :span="4" class="label">生成预览</el-col>
                <el-col :span="18">
                    <span style="word-break: keep-all;" v-html="preview"></span>
                </el-col>
            </el-row>
 

            <template #footer>
                <el-button @click="display = false">取 消</el-button>
                <el-button type="primary" @click="click_done">确 定</el-button>
            </template>
        </el-dialog>
    </div>
	

</template>
<script>
//import {inject} from 'vue'
export default {
	name:'HouseAdd',
	emits:["house_update"],
    data:function(){
    	return {
    		display:false,
            cascader_key:0,
            pid_type_intro:'选择添加方式查看说明',
            level:[],
            nodes:[],
            preview:'',
    		data_for_edit:{
                type:'',
    			pid_type:'',
                pid:'',
                level:'',
                tpl:'',
    		},
            props: {
                label:'name',
                value:'id',
                lazy: true,
                checkStrictly:true,
                lazyLoad:(node, resolve)=>{
                    
                    let post = {};
                    if(node.value && !isNaN(node.value)){ post.id = node.value;}
                    this.axios.post("/api/house/list",post).then(ret=>{
                        if(!ret.ok){ this.$message.error(ret.msg); return;}
                        ret.data.map(item=>{ item.leaf = item.hasChildren == 0;})
                        resolve(ret.data);
                    });
                },
            }
    	};
    },
    watch:{
        'data_for_edit.pid_type':function(val){
            if(val == '1'){
                this.pid_type_intro = '为选中的父节点添加子节点，若未选父节点，生成一级节点';
            }else if(val == '2'){
                this.pid_type_intro = '为第N级节点（N从1开始）添加子节点，若选择了父节点，则只在所选父节点下进行添加';
                this.load_level();
            }else{
                this.pid_type_intro = '选择添加方式查看说明';
            }
        },//pid_type
        'data_for_edit.pid':function(){
            if(this.data_for_edit.pid_type != '2'){ return; }
            this.load_level();
        }
    },//watch
    created:function(){
        //this.load_tags();
    },//created
    mounted:function(){

    },//mounted
    methods:{
        load_level:function(){
            this.axios.post("/api/house/level",{pid:this.data_for_edit.pid}).then(ret=>{
                if(!ret.ok){ this.$message.error(ret.msg); return; }
                this.level = ret.data;
                console.log(this.level);
            });
        },//load_tags
    	show:function(){
    		this.data_for_edit = {
                type:'',
                pid_type:'',
                pid:'',
                level:'',
                tpl:'',
            };
            this.preview = '';
    		this.display = true;
            ++this.cascader_key;
            
    	},//show
        click_test:async function(){
            this.preview = "";
            let tpl = this.data_for_edit.tpl;
            this.nodes = [];
            let res = tpl.match(/\{(.+?)\}/ig);
            if(res != null && Array.isArray(res)){
                //发现有表达式
                for(let i=0; i<res.length; i++){
                    //循环处理多个表达式
                    if(res[i].substr(0,5) == '{name' || res[i].substr(0,6) == '{value'){
                        //引用表达式
                        if(this.data_for_edit.pid_type == '1' && this.data_for_edit.pid == ''){
                            this.$message.error("name 或 value 指令不能在添加一级节点时使用");
                            return;
                        }
                        if(this.data_for_edit.pid_type=='2' && (this.data_for_edit.level == '' || isNaN(this.data_for_edit.level))){ this.$message.error("name 或 value 指令需选择层级。"); return;}

                        let command = res[i].substr(1,res[i].indexOf("e"));//name 或 value
                        let pad = /\|pad:(\d+)/ig.exec(res[i]);
                        let ret = await this.axios.post("/api/house/node_name",{want:command, type:this.data_for_edit.pid_type, pid:this.data_for_edit.pid, level:this.data_for_edit.level});
                        if(!ret.ok){ this.$message.error(ret.msg); return;}
                        if(Array.isArray(pad) && pad.length == 2){
                            ret.data = ret.data.padStart(parseInt(pad[1]),"0");
                        }
                        tpl = tpl.replace(res[i], ret.data);
                        this.preview = tpl.replace(res[i], ret.data);
                        //this.nodes.push({name:tpl, value: tpl});
                    }else if(res[i].substr(0,7) == '{range:'){
                        //范围表达式
                        this.nodes = [];
                        let range = /(\d+),(\d+)/ig.exec(res[i]);
                        if(!range || !Array.isArray(range) || range.length != 3){ this.$message.error("range 指令解析错误。"); return false;}
                        let s = parseInt(range[1]);
                        let e = parseInt(range[2]);
                        let pad = /pad:(\d+)/ig.exec(res[i]);
                        let need_len = 0;
                        if(pad!=null && Array.isArray(pad) && pad.length == 2){ need_len = parseInt(pad[1]); }

                        for(let j=s; j<=e; j++){
                            let val = need_len > 0 ? (''+j).padStart(need_len, '0'): j;
                            this.nodes.push({name:tpl.replace(res[i], val), value: val});
                        }
                        let buff = [];
                        this.nodes.map(item=>{
                            buff.push(item.name);
                        })
                        this.preview = buff.join("、");
                    }
                }
            }else{
                this.preview = tpl;
            }
        },//click_test
    	click_done:async function(){
            if(this.data_for_edit.type == ''){ this.$message.error("请选择房屋类型"); return;}
    		if(this.data_for_edit.pid_type == ''){ this.$message.error("请选择添加方式"); return;}
            if(this.data_for_edit.pid_type == '2' && this.data_for_edit.level == ''){ this.$message.error("请选择节点层级"); return; }
    		if(this.data_for_edit.tpl == ''){ this.$message.error("请输入名称模板"); return;}

            await this.click_test();
            if(this.preview == ''){ this.$message.error("名称模板解析有误"); return; }

    		this.axios.post("/api/house/add",this.data_for_edit).then(ret=>{
    			if(!ret.ok){ this.$message.error(ret.msg); return; }
    			this.$message.success("操作成功");
    			this.$emit("house_update");
    			this.display = false;
    		});
    	},//click_done
    }
}
</script>